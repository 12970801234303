import React, { useEffect, useState } from 'react';
import { fetchItems, updateItem, deleteItem } from './api';

function AdminPage() {
    const [items, setItems] = useState([]);
    const [editingItem, setEditingItem] = useState({});

    useEffect(() => {
        loadItems();
    }, []);

    const loadItems = async () => {
        const data = await fetchItems();
        setItems(data);
    };

    const handleEditChange = (id, field, value) => {
        setEditingItem(prev => ({
            ...prev,
            [id]: { ...prev[id], [field]: value }
        }));
    };

    const handleSave = async (id) => {
        const originalItem = items.find(item => item.id === id);
        const updatedItem = { ...originalItem, ...editingItem[id] };

        const success = await updateItem(updatedItem);
        if (success) {
            alert('Item updated successfully!');
            loadItems();
            setEditingItem(prev => ({ ...prev, [id]: {} }));
        } else {
            alert('Failed to update item.');
        }
    };

    const handleDelete = async (id) => {
        const success = await deleteItem(id);
        if (success) {
            alert('Item deleted successfully!');
            loadItems();
        } else {
            alert('Failed to delete item.');
        }
    };

    const handleExportToCSV = () => {
        const csvHeaders = ['_ID_', '_MAIN_CATEGORY_', '_NAME_', '_MODEL_', '_SKU_', '_MANUFACTURER_', '_PRICE_', '_OPTIONS_'];
        const csvRows = items.map(item => [
            `"${item.id}"`,                 // _ID_
            `""`,                           // _MAIN_CATEGORY_ (Empty)
            `"${item.title || ''}"`,        // _NAME_ (title field without splitting)
            `"${item.model || ''}"`,        // _MODEL_
            `""`,                           // _SKU_ (Empty)
            `""`,                           // _MANUFACTURER_ (Empty)
            `"${item.price || ''}"`,        // _PRICE_
            `""`                            // _OPTIONS_ (Empty)
        ]);

        const csvContent = [
            csvHeaders.join(','), 
            ...csvRows.map(row => row.join(','))
        ].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'items_export.csv';
        link.click();
        URL.revokeObjectURL(url);
    };

    return (
        <div>
            <h1>Admin</h1>
            <button onClick={handleExportToCSV}>Export to CSV</button>
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Название</th>
                        <th>Модель</th>
                        <th>Наличие</th>
                        <th>Цена</th>
                        <th>Действия</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((item) => (
                        <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>
                                <input
                                    type="text"
                                    value={editingItem[item.id]?.title || item.title}
                                    onChange={(e) =>
                                        handleEditChange(item.id, 'title', e.target.value)
                                    }
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    value={editingItem[item.id]?.model || item.model}
                                    onChange={(e) =>
                                        handleEditChange(item.id, 'model', e.target.value)
                                    }
                                />
                            </td>
                            <td>
                                <select
                                    value={editingItem[item.id]?.availability || item.availability}
                                    onChange={(e) =>
                                        handleEditChange(item.id, 'availability', e.target.value)
                                    }
                                >
                                    <option value="available">Available</option>
                                    <option value="unavailable">Unavailable</option>
                                    <option value="delivery">Delivery</option>
                                </select>
                            </td>
                            <td>
                                <input
                                    type="text"
                                    value={editingItem[item.id]?.price || item.price}
                                    onChange={(e) =>
                                        handleEditChange(item.id, 'price', e.target.value)
                                    }
                                />
                            </td>
                            <td>
                                <button onClick={() => handleSave(item.id)}>Save</button>
                                <button onClick={() => handleDelete(item.id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default AdminPage;
